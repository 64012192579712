$x0_5: 4px;
$x1: 8px;
$x1_5: 12px;
$x2: 16px;
$x2_5: 20px;
$x3: 24px;
$x3_5: 28px;
$x4: 32px;
$x4_5: 36px;
$x5: 40px;
$x5_5: 44px;
$x6: 48px;
$x6_5: 52px;
$x7: 56px;
$x8: 64px;
$x9: 72px;
$x10: 80px;
$x11: 88px;
$x12: 96px;
$x16: 128px;
$width-max-xxs: 479px;
$width-xs: 480px;
$width-s: 576px;
$width-max-s: 767px;
$width-m: 768px;
$width-max-m: 991px;
$width-l: 992px;
$width-l-plus: 1080px;
$width-xl: 1140px;
$width-xxl: 1440px;

$padding-horizontal-s: $x3;
$padding-horizontal-m: $x4_5;
$padding-horizontal-l: $x5_5;
$padding-outer-horizontal-s: $x3;
$padding-outer-horizontal-m: $x4_5;
$padding-outer-horizontal-l: $x5_5;
$padding-outer-horizontal-xl: $x6_5;

$header-height: 60px;
$content-height: calc(100vh - 60px);
$content-max-width: 1140px;
$content-outer-margin: calc(50vw - #{$content-max-width / 2});

$line-height-p-16: calc(16px * 1.66);
$line-height-p-17: calc(17px * 1.66);
$line-height-p-18: calc(18px * 1.66);

$color-white: #ffffff;
$color-black: #000000;
$color-lightgray: #e4e4e4;
$color-lightgray-light: #e8e8e8;
$color-lightgray-hover: #e8e8e8;
$color-lightgray-light-hover: #ececec;
$color-lightgray-ultralight: #fcfcfc;
$color-darkblue: #0b4269;
$color-mediumblue: #9ec8ea;
$color-blue: #a4d3f2;
$color-blue-light: #edf6fc; // 20% Opacity
$color-blue-ultralight: #f6fbfe; //10% Opacity
$color-blue-hyperlight: #fafdfe; //5% Opacity
$color-beige: #f4f1e9;
$color-beige-light: #fbf9f6; //40% Opacity
$color-beige-ultralight: #fdfcfb; // 20% Opacity
$color-beige-hyperlight: #fefefd; // 10% Opacity
$color-pink: #efbfcb;
$color-pink-light: #fcf2f5; // 20% Opacity
$color-pink-ultralight: #fdf9fa; // 10% Opacity
$color-pink-hyperlight: #fefefd; // 5% Opacity
$color-button-hover: #fdfdff;

$border-radius: 3px;

$box-shadow-bottomright: 1.5px 1.5px 3px 0px #d9d9d9;
$box-shadow-bottomright-half: 0.5px 0.5px 1px 0px #d9d9d9;
$box-shadow-left: -1.5px 0px 1.5px 0px #d9d9d9;
$box-shadow-right: 1.5px 0px 1.5px 0px #d9d9d9;
$box-shadow-topleft: -1.5px -1.5px 3px 0px #d9d9d9;
$box-shadow-topleft-half: -0.5px -0.5px 1px 0px #d9d9d9;
$box-shadow-below: 0px 1px 2px 0px #cccccc;
$box-shadow-below-half: 0px 1px 1px 0px #cccccc;
$box-shadow-around: $box-shadow-bottomright, $box-shadow-topleft-half;
$box-shadow-around-inset: inset $box-shadow-bottomright,
  inset $box-shadow-topleft-half;
$box-shadow-sides: $box-shadow-left, $box-shadow-right;

$text-s: 16px;
$text-m: 17px;
$text-l: 18px;

$text-small-s: 14px;
$text-small-m: 15px;
$text-small-l: 16px;

$text-extrasmall-s: 12px;
$text-extrasmall-m: 13px;
$text-extrasmall-l: 14px;

$outer-padding-content-block-s: $x6 $padding-outer-horizontal-s $x7;
$outer-padding-content-block-m: $x7 $padding-outer-horizontal-m $x8;
$outer-padding-content-block-l: $x8 $padding-outer-horizontal-l $x9;
$outer-padding-content-block-xl: $x9 $padding-outer-horizontal-xl $x10;

$inner-padding-content-block-s: $x3_5 $padding-horizontal-s $x4_5;
$inner-padding-content-block-m: $x4_5 $padding-horizontal-m $x5_5;
$inner-padding-content-block-l: $x6 $padding-horizontal-l $x7;
$inner-padding-content-block-negative-s: (-$x3_5) (-$padding-horizontal-s)
  (-$x4_5);

$inner-gap-extrasmall-s: $x0_5;
$inner-gap-extrasmall-m: $x1;
$inner-gap-extrasmall-l: $x1;
$inner-gap-small-s: $x1;
$inner-gap-small-m: $x1_5;
$inner-gap-small-l: $x1_5;
$inner-gap-s: $x1_5;
$inner-gap-m: $x2;
$inner-gap-l: $x2_5;
$inner-gap-medium-s: $x2;
$inner-gap-medium-m: $x2_5;
$inner-gap-medium-l: $x3;
$inner-gap-large-s: $x3;
$inner-gap-large-m: $x3_5;
$inner-gap-large-l: $x4_5;

$outer-gap-s: $x1_5;
$outer-gap-m: $x5;
$outer-gap-l: $x6;
$outer-gap-xl: $x7;

$extra-gap-s: $x4;
$extra-gap-m: $x6;
$extra-gap-l: $x10;
$extra-gap-xl: $x12;

$inner-half-horizontal-padding-content-block-s: $x1;
$inner-half-horizontal-padding-content-block-m: $x2;
$inner-half-vertical-padding-content-block-s: $x1;
$inner-half-vertical-padding-content-block-m: $x2;

$inner-even-vertical-padding-content-block-s: $x4;
$inner-even-half-vertical-padding-content-block-s: $x3;
$inner-even-vertical-padding-content-block-m: $x5;
$inner-even-half-vertical-padding-content-block-m: $x3_5;
$inner-even-vertical-padding-content-block-l: $x6;
$inner-even-half-vertical-padding-content-block-l: $x4;
$outer-extra-padding-content-block-s: $x4;

$extra-padding-content-block-s: $x7 $x6 $x8;
$extra-padding-content-block-m: $x8 $x7 $x9;
$extra-padding-content-block-l: $x9 $x8 $x10;
$extra-padding-content-block-xl: $x10 $x9 $x11;

@function increaseSize($currentSize, $increaseFactor: 1.025) {
  @return $currentSize * $increaseFactor;
}

@function increaseFontSize($currentSize, $increaseSize: 1px) {
  @return $currentSize + $increaseSize;
}

@function getHorizontalPadding($screenSize: null) {
  @if $screenSize == "l" {
    @return $padding-horizontal-l;
  } @else if $screenSize == "m" {
    @return $padding-horizontal-m;
  } @else {
    @return $padding-horizontal-s;
  }
}

@mixin setOuterPaddings($noVerticalPadding: false, $extraSidePadding: false) {
  padding: $outer-padding-content-block-s;
  gap: $outer-gap-s;

  @media only screen and (min-width: $width-m) {
    padding: $outer-padding-content-block-m;
    gap: $outer-gap-m;
  }
  @media only screen and (min-width: $width-l) {
    padding: $outer-padding-content-block-l;
    gap: $outer-gap-l;
  }
  @media only screen and (min-width: $width-xl) {
    padding: $outer-padding-content-block-xl;
    gap: $outer-gap-xl;
  }

  @if $noVerticalPadding {
    padding-top: 0px;
    padding-bottom: 0px;
    @media only screen and (min-width: $width-m) {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
  @if $extraSidePadding {
    padding-left: $outer-extra-padding-content-block-s;
    padding-right: $outer-extra-padding-content-block-s;
  }
}

@mixin setInnerPaddings(
  $removeBottomPadding: false,
  $removeTopPadding: false,
  $removeSidePadding: false,
  $halfHorizontalPadding: false,
  $halfVerticalPadding: false,
  $verticalAlignCenter: false,
  $doubleGap: false
) {
  padding: $inner-padding-content-block-s;
  @media only screen and (min-width: $width-m) {
    padding: $inner-padding-content-block-m;
  }
  @media only screen and (min-width: $width-l) {
    padding: $inner-padding-content-block-l;
  }

  @if ($halfHorizontalPadding == "s" or $halfHorizontalPadding == "sm") {
    padding-left: $inner-half-horizontal-padding-content-block-s;
    padding-right: $inner-half-horizontal-padding-content-block-s;
  }
  @if $halfHorizontalPadding == "m" or $halfHorizontalPadding == "sm" {
    @media only screen and (min-width: $width-m) {
      padding-left: $inner-half-horizontal-padding-content-block-m;
      padding-right: $inner-half-horizontal-padding-content-block-m;
    }
  }

  @if $halfVerticalPadding == "s" or $halfVerticalPadding == "sm" {
    padding-top: $inner-half-vertical-padding-content-block-s;
    padding-bottom: $inner-half-vertical-padding-content-block-s;
  }

  @if $halfHorizontalPadding == "m" or $halfHorizontalPadding == "sm" {
    @media only screen and (min-width: $width-m) {
      padding-top: $inner-half-vertical-padding-content-block-m;
      padding-bottom: $inner-half-vertical-padding-content-block-m;
    }
  }

  @if $removeBottomPadding == "s" or $removeBottomPadding == "sm" {
    padding-bottom: 0px;
  }

  @if $removeBottomPadding == "m" or $removeBottomPadding == "sm" {
    @media only screen and (min-width: $width-m) {
      padding-bottom: 0px;
    }
  }

  @if $removeTopPadding == "s" or $removeTopPadding == "sm" {
    padding-top: 0px;
  }

  @if $removeTopPadding == "m" or $removeTopPadding == "sm" {
    @media only screen and (min-width: $width-m) {
      padding-top: 0px;
    }
  }

  @if $removeSidePadding == "s" or $removeSidePadding == "sm" {
    padding-left: 0px;
    padding-right: 0px;
  }

  @if $removeSidePadding == "m" or $removeSidePadding == "sm" {
    @media only screen and (min-width: $width-m) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  @if $verticalAlignCenter {
    @if $halfVerticalPadding == "s" or $halfVerticalPadding == "sm" {
      padding-top: $inner-even-half-vertical-padding-content-block-s;
      padding-bottom: $inner-even-half-vertical-padding-content-block-s;
    } @else {
      padding-top: $inner-even-vertical-padding-content-block-s;
      padding-bottom: $inner-even-vertical-padding-content-block-s;
    }

    @if $halfVerticalPadding == "m" or $halfVerticalPadding == "sm" {
      @media only screen and (min-width: $width-m) {
        padding-top: $inner-even-half-vertical-padding-content-block-m;
        padding-bottom: $inner-even-half-vertical-padding-content-block-m;
      }
      @media only screen and (min-width: $width-l) {
        padding-top: $inner-even-half-vertical-padding-content-block-l;
        padding-bottom: $inner-even-half-vertical-padding-content-block-l;
      }
    } @else {
      @media only screen and (min-width: $width-m) {
        padding-top: $inner-even-vertical-padding-content-block-m;
        padding-bottom: $inner-even-vertical-padding-content-block-m;
      }
      @media only screen and (min-width: $width-l) {
        padding-top: $inner-even-vertical-padding-content-block-l;
        padding-bottom: $inner-even-vertical-padding-content-block-l;
      }
    }
  }

  @if $doubleGap {
    gap: $inner-gap-large-s;
  } @else {
    gap: $inner-gap-s;
  }

  @media only screen and (min-width: $width-m) {
    @if $doubleGap {
      gap: $inner-gap-large-m;
    } @else {
      gap: $inner-gap-m;
    }
  }

  @media only screen and (min-width: $width-l) {
    @if $doubleGap {
      gap: $inner-gap-large-l;
    } @else {
      gap: $inner-gap-l;
    }
  }
}

@mixin setExpanderPaddings($noSidePaddings: false) {
  @if $noSidePaddings {
    padding: 24px 0px;
    @media only screen and (min-width: $width-m) {
      padding: 32px 0px;
    }
    @media only screen and (min-width: $width-l) {
      padding: 36px 0px;
    }
  } @else {
    padding: 24px;
    @media only screen and (min-width: $width-m) {
      padding: 32px;
    }
    @media only screen and (min-width: $width-l) {
      padding: 36px;
    }
  }
}

@mixin setExtraPaddings() {
  padding: $extra-padding-content-block-s;
  gap: $extra-gap-s;
  @media only screen and (min-width: $width-m) {
    padding: $extra-padding-content-block-m;
    gap: $extra-gap-m;
  }
  @media only screen and (min-width: $width-l) {
    padding: $extra-padding-content-block-l;
    gap: $extra-gap-l;
  }
  @media only screen and (min-width: $width-xl) {
    padding: $extra-padding-content-block-xl;
    gap: $extra-gap-xl;
  }
}

@mixin setGap($gapSize: null) {
  @if $gapSize == "large" {
    gap: $inner-gap-large-s;
  } @else if $gapSize == "medium" {
    gap: $inner-gap-medium-s;
  } @else if $gapSize == "small" {
    gap: $inner-gap-small-s;
  } @else if $gapSize == "extrasmall" {
    gap: $inner-gap-extrasmall-s;
  } @else {
    gap: $inner-gap-s;
  }
  @media only screen and (min-width: $width-m) {
    @if $gapSize == "large" {
      gap: $inner-gap-large-m;
    } @else if $gapSize == "medium" {
      gap: $inner-gap-medium-m;
    } @else if $gapSize == "small" {
      gap: $inner-gap-small-m;
    } @else if $gapSize == "extrasmall" {
      gap: $inner-gap-extrasmall-m;
    } @else {
      gap: $inner-gap-m;
    }
  }
  @media only screen and (min-width: $width-l) {
    @if $gapSize == "large" {
      gap: $inner-gap-large-l;
    } @else if $gapSize == "medium" {
      gap: $inner-gap-medium-l;
    } @else if $gapSize == "small" {
      gap: $inner-gap-small-l;
    } @else if $gapSize == "extrasmall" {
      gap: $inner-gap-extrasmall-l;
    } @else {
      gap: $inner-gap-l;
    }
  }
}

@mixin setContentMaxWidth() {
  max-width: $content-max-width;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

@mixin setNavigationGap() {
  gap: $x3;
  margin-left: 236px;

  @media only screen and (min-width: $width-m) {
    margin-left: 236px;
  }
  @media only screen and (min-width: $width-l) {
    margin-left: 252px;
  }
  @media only screen and (min-width: $width-l) {
    margin-left: 268px;
  }
}

//Todo: remove if not used
@mixin setVerticalShadowLine() {
  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    left: calc(0px - #{getHorizontalPadding()});
    right: calc(0px - #{getHorizontalPadding()});
    bottom: 0px;
    box-shadow: $box-shadow-below-half;
    @media only screen and (min-width: $width-m) {
      left: calc(0px - #{getHorizontalPadding("m")});
      right: calc(0px - #{getHorizontalPadding("m")});
    }
    @media only screen and (min-width: $width-l) {
      left: calc(0px - #{getHorizontalPadding("l")});
      right: calc(0px - #{getHorizontalPadding("l")});
    }
  }
}

@mixin marginToPadding(
  $margin: null,
  $marginNegative: null,
  $marginTop: null,
  $marginSide: null,
  $marginBottom: null
) {
  @if $margin and $marginNegative {
    padding: $margin;
    margin: $marginNegative;
  }
  @if ($marginTop) {
    margin-top: (-$marginTop);
    padding-top: $marginTop;
  }
  @if ($marginSide) {
    margin-left: (-$marginSide);
    padding-left: $marginSide;
    margin-right: (-$marginSide);
    padding-right: $marginSide;
  }
  @if ($marginBottom) {
    margin-bottom: (-$marginBottom);
    padding-bottom: $marginBottom;
  }
}

@mixin mergeBannerWithTopContainer {
  @media only screen and (max-width: $width-max-s) {
    .banner-about,
    .banner-services {
      .banner-tagline {
        padding-bottom: 20px;
        box-shadow: none;
      }
    }
    .info-card-container-value,
    .info-card-container-service-units {
      .info-card-container-content-wrapper {
        padding-top: 0px;
      }
    }
  }
}

@mixin alignServiceHeaderWithCotentBlock {
  .service-content-block-container {
    background-color: green($color: #000000);
    .content-block-container-content-wrapper {
      padding-top: 36px;
    }
    @media only screen and (min-width: $width-xs) {
      .content-block-container-content-wrapper {
        padding-top: 40px;
      }
    }
    @media only screen and (min-width: $width-m) {
      .content-block-container-content-wrapper {
        padding-top: 0px;
      }

      &:not(:last-of-type) {
        .content-block-container-content-wrapper {
          padding-bottom: 20px;
        }
      }
    }
  }
  .service-header-block-content-wrapper {
    @include setOuterPaddings(false, true);
    padding-bottom: 24px;
    @media only screen and (min-width: $width-xs) {
      padding-bottom: 28px;
    }
    @media only screen and (min-width: $width-s) {
      padding-bottom: 32px;
    }
  }
}

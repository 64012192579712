@import "../variables";

@mixin tranformExpander($extra: 0px) {
  transform: translateY(-48px - $extra);
  margin-bottom: (-48px - $extra);
  @media only screen and (min-width: $width-m) {
    transform: translateY(-84px - $extra);
    margin-bottom: (-84px - $extra);
  }
  @media only screen and (min-width: $width-l) {
    transform: translateY(-104px - $extra);
    margin-bottom: (-104px - $extra);
  }
  @media only screen and (min-width: $width-xl) {
    transform: translateY(-112px - $extra);
    margin-bottom: (-112px - $extra);
  }
}

.expander {
  display: flex;
  flex-direction: column;
  @include setInnerPaddings("sm", "sm", false, "sm", "sm", false, false);
}

.expander-information {
  position: relative;
  display: flex;
  flex-direction: column;
  @include setGap();
}

.expander-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  @include setGap();
}
.expander-list-title-icon {
  position: absolute;
}

.expander-service-more-information {
  box-shadow: $box-shadow-around-inset, $box-shadow-sides;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  @include setExpanderPaddings();
  @include tranformExpander();
}

.expander-service-info-card-more-information {
  @include setExpanderPaddings(true);
  @include setGap("large");
  @include tranformExpander(6px);

  .expander-list {
    padding: 0px 12px;
  }

  .expander-list-title {
    color: $color-darkblue;
    padding-left: 26px;
  }

  .expander-list-title-icon {
    color: $color-darkblue;
    position: absolute;
    top: 0px;
    left: 3px;
  }
}

.expander-close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  height: auto;
  span {
    font-size: 20px;
  }
}

@media only screen and (min-width: $width-l) {
  .expander-service-more-information {
    flex-direction: row;
    .expander-list-item-wrapper {
      column-count: 2;
      column-gap: 100px;
      column-width: 50%;
    }
    .expander-list-title {
      padding-top: 3px;
    }
    .expander-list-title-icon {
      top: 3px;
    }
  }

  .expander-service-info-card-more-information {
    > * {
      width: 50%;
    }
    .expander-list-item-wrapper {
      column-count: 1;
    }
  }
}

@import "../variables";

.info-card-service {
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 480px;
  padding: 24px;
  .info-card-icon-wrapper {
    display: block;
    height: 40px;
  }
  .info-card-text {
    text-align: start;

    h4 {
      line-height: 1;
      white-space: normal;
    }
    h4:not(:first-of-type) {
      padding-top: 4px;
    }
  }

  @media only screen and (min-width: $width-xs) and (max-width: $width-max-s) {
    .info-card-text {
      display: flex;
      h4 {
        font-size: 19px;
      }
      h4:not(:first-of-type) {
        padding-top: 0px;
        padding-left: 6px;
      }
    }
  }

  @media only screen and (min-width: $width-m) {
    align-items: flex-start;
    gap: 0px;
    .info-card-icon-wrapper {
      height: 45px;
      display: flex;
      justify-content: flex-end;
    }
    .info-card-text {
      margin-bottom: auto;
      h4 {
        font-size: 19px;
        line-height: 1.1em;
      }
    }
  }

  @media only screen and (min-width: $width-l) {
    h4 {
      font-size: 21px;
      line-height: 1em;
    }
  }
}

@import "../variables";

.slogan-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include setExtraPaddings();
  @include setContentMaxWidth();
}

.slogan-image-wrapper {
  display: flex;
  justify-content: center;
}

.slogan-image {
  box-sizing: border-box;
  width: 80%;
  max-width: 360px;
}

.slogan-tagline {
  display: flex;
  flex-direction: column;
  text-align: center;
  @include setGap();
}
.slogan-tagline-text {
  max-width: 440px;
}
@media only screen and (min-width: $width-m) {
  .slogan-content-wrapper {
    flex-direction: row-reverse;
    height: $content-height;
    > * {
      width: 50%;
    }
  }
  .slogan-image {
    min-width: 310px;
    max-width: 400px;
    margin-left: auto;
  }
  .slogan-tagline {
    text-align: start;
    margin-right: auto;
  }
  .slogan-tagline-text {
    min-width: 310px;
    max-width: 400px;
  }
}

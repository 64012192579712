@import "../variables";

@include mergeBannerWithTopContainer();

.banner {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.banner-content-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  @include setContentMaxWidth();
  width: 100%;
  height: auto;
}

.banner-image-wrapper {
  line-height: 0;
}

.banner-tagline {
  z-index: 1;
  flex: 1 0 auto;
  min-height: 150px;
  box-shadow: $box-shadow-below;
  @include setOuterPaddings(true, true);
  padding-top: 36px;
  padding-bottom: 44px;
  h3 {
    line-height: 1.5em;
  }
}

.banner-tagline-content-wrapper {
  display: flex;
  flex-direction: column;
  @include setGap("medium");
}

.banner-image {
  flex: 0 1 auto;
  object-fit: cover;
  width: 100%;
  height: 100vh;
  min-height: 250px;
  max-height: 100vw;
}

@media only screen and (min-width: $width-s) {
  .banner-tagline {
    padding-top: 44px;
    padding-bottom: 52px;
  }
}

@media only screen and (min-width: $width-m) {
  .banner {
    height: $content-height;
  }
  .banner-tagline {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 360px;
    max-width: 600px;
    width: 50%;
    padding-top: 56px;
    box-shadow: none;

    h3 {
      line-height: 1.66em;
    }
    button {
      margin-right: auto;
    }
  }

  .banner-tagline-background-overlay {
    background-color: rgba(255, 255, 255, 0.5);
    height: $content-height;
    width: auto;
    min-width: 0px;
    max-width: 100vw;
    .banner-tagline-content-wrapper {
      box-sizing: content-box;
      padding-left: $content-outer-margin;
      width: 35vw;
      max-width: 400px;
    }
  }

  .banner-tagline-background-overlay-whiter {
    background-color: rgba(255, 255, 255, 0.75);
  }

  .banner-tagline-background-overlay-reverse {
    left: unset;
    right: 0;
    margin-left: 0;
    .banner-tagline-content-wrapper {
      padding-left: 0;
      padding-right: $content-outer-margin;
    }
  }

  .banner-image {
    height: 100%;
    position: absolute;
    top: 0;
    max-height: 100vh;
  }
}

//Specific classes for each page
.banner-home {
  max-height: $content-height;

  .banner-image-wrapper {
    overflow: hidden;
  }
  .banner-tagline-content-wrapper {
    max-width: 400px;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (min-width: $width-m) {
    .banner-tagline-content-wrapper {
      align-items: flex-start;
      text-align: start;
      max-width: 350px;
      margin-left: 0;

      button {
        width: auto;
        margin-left: 0;
      }
    }
  }
  @media only screen and (min-width: $width-l) {
    .banner-tagline-content-wrapper {
      max-width: 500px;
    }
  }

  .banner-image {
    object-position: 80% 0;
    @media only screen and (min-width: $width-m) {
      object-position: 70% 0;
    }
    @media only screen and (min-width: $width-xl) {
      object-position: 75% 0;
    }
  }
}

.banner-about {
  .banner-image {
    object-position: 100% 0;
    @media only screen and (min-width: $width-m) {
      object-position: 75% 0;
    }
  }
}

.banner-services {
  .banner-image {
    filter: grayscale(40%);
    object-position: 20% 0;
    @media only screen and (min-width: $width-m) {
      object-position: 45% 0;
    }
    @media only screen and (min-width: $width-l) {
      object-position: 70% 0;
    }
  }
}

.banner-about,
.banner-services {
  @media only screen and (min-width: $width-xl) {
    .banner-tagline-background-overlay {
      margin-left: calc(0px - #{$content-outer-margin});
    }
    .banner-tagline-background-overlay-reverse {
      margin-right: calc(0px - #{$content-outer-margin});
    }
  }
}

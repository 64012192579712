@import "../variables";

.service-liftup {
  position: relative;
}
.service-liftup-content-upper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $x2;
  padding-bottom: $x4;
  padding-top: $x6;

  h2 {
    font-weight: 600;
  }
}

.service-liftup-content-lower {
  display: flex;
  flex-direction: column;
  gap: $inner-gap-s;
  padding-top: $x3;
}

.service-liftup-image-wrapper {
  padding-top: $x3;
}

.service-liftup-image {
  width: 100%;
}

.service-liftup-color {
  position: absolute;
  bottom: 0px;
  width: 100vw;
  height: 100%;
  left: -$padding-horizontal-s;
  z-index: -1;
  overflow: hidden;
}

.service-liftup-service-list-container {
  padding: $x1 $x2;
  border-left: 8px solid;
  p,
  span {
    color: black;
  }
  p {
    padding-bottom: $x0_5;
  }
}

@media only screen and (min-width: $width-m) {
  .service-liftup-content-upper {
    padding-top: $x7;
  }

  .service-liftup-color {
    left: -$padding-horizontal-m;
  }
}

@media only screen and (min-width: $width-l) {
  .service-liftup {
    display: flex;
  }

  .service-liftup-content {
    width: 50%;
    padding-right: $x5_5;
  }

  .service-liftup-content-upper {
    padding-top: $x11;
    gap: $x3;
    padding-bottom: $x4_5;
  }

  .service-liftup-content-lower {
    padding-top: $x3_5;
  }

  .service-liftup-color {
    left: -$padding-horizontal-l;
  }

  .service-liftup-image-wrapper {
    position: absolute;
    right: -$padding-horizontal-l;
    width: 50vw;
    height: 100%;
    padding-top: $x11;
  }

  .service-liftup-image {
    height: 100%;
    object-fit: cover;
  }
  .service-liftup-reverse {
    .service-liftup-content {
      margin-left: 50%;
      padding-right: 0px;
      padding-left: $x5_5;
    }
    .service-liftup-color {
      left: unset;
      right: -$padding-horizontal-l;
    }
    .service-liftup-image-wrapper {
      right: unset;
      left: -$padding-horizontal-l;
    }
  }
}

@media only screen and (min-width: $width-xl) {
  .service-liftup-content {
    padding-right: $x7;
  }
  .service-liftup-color {
    left: calc(518px - 50vw);
  }
  .service-liftup-image-wrapper {
    left: unset;
    right: calc(518px - 50vw);
  }
  .service-liftup-reverse {
    .service-liftup-content {
      padding-left: $x7;
    }
    .service-liftup-color {
      left: unset;
      right: calc(518px - 50vw);
    }
    .service-liftup-image-wrapper {
      right: unset;
      left: calc(518px - 50vw);
    }
  }
}

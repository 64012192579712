@import "../variables";

.info-card-container-content-wrapper {
  display: flex;
  flex-direction: column;
  @include setOuterPaddings(false, true);
  @include setGap("large");
  @include setContentMaxWidth();
}
.info-card-text-wrapper {
  display: flex;
  flex-direction: column;
  @include setGap();
}
.info-card-container-card-wrapper {
  display: flex;
  position: relative;
}
.info-card-container-button {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  padding: 0px;
  border-radius: 50%;
  box-shadow: $box-shadow-around;
  span {
    font-size: 20px;
  }

  &:last-of-type {
    left: unset;
    right: -20px;
  }
}
.info-card-container-cards {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include setGap();
}
.info-card-post-text-wrapper {
  display: flex;
  flex-direction: column;
  @include setInnerPaddings("sm", false, fale, "sm", "sm");
  @include setGap("small");
}

@media only screen and (min-width: $width-m) {
  .info-card-container-cards {
    flex-direction: row;
  }
}

//Scrollable container
.info-card-scroll-container-card-wrapper {
  .info-card-ref {
    padding: 2px;
  }
}

//Value cards
.info-card-container-value {
  .info-card-container-content-wrapper {
    @include setGap("medium");
  }
  .info-card-container-cards {
    gap: 4px;
    flex-direction: row;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .info-card-active:hover {
    background-color: $color-white;
  }
  .expander {
    @include setExpanderPaddings();
    @include setGap("small");
    border-radius: $border-radius;
  }

  @media only screen and (min-width: $width-m) {
    .info-card-container-button {
      display: none;
    }
    .info-card-container-cards {
      @include setGap();
    }
  }
}

//Service unit cards
.info-card-container-service-units {
  @media only screen and (min-width: $width-m) {
    .info-card-ref {
      width: calc(33.33% - 10.67px);
    }
  }
  @media only screen and (min-width: $width-l) {
    .info-card-ref {
      width: calc(33.33% - 13.33px);
    }
  }
}

//Service cards
.info-card-container-service {
  .info-card-container-content-wrapper {
    padding: 0;
  }
  .info-card-container-service-expander-open {
    .info-card-value {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &.info-card-active {
        border-bottom: 0px;
      }
    }
  }
  @media only screen and (min-width: $width-m) {
    .info-card-container-cards {
      gap: 6px;
    }
  }
  @media only screen and (min-width: $width-l) {
    .info-card-container-cards {
      @include setGap("medium");
    }
  }
}

//Contact and pricing cards
.info-card-container-contact,
.info-card-container-pricing {
  .info-card-container-cards {
    gap: 32px 24px;
  }
}

//Pricing cards
.info-card-container-pricing {
  @media only screen and (min-width: $width-m) {
    .info-card-container-cards {
      flex-wrap: wrap;
    }
  }
}

//Contact cards
.info-card-container-contact {
  @media only screen and (min-width: $width-m) {
    .info-card-container-cards {
      flex-direction: column;
    }
  }
  @media only screen and (min-width: $width-l-plus) {
    .info-card-container-cards {
      flex-direction: row;
    }
  }
}

//Service units
.info-card-container-service-units {
  .info-card-post-text-wrapper {
    padding-left: 0px;
    padding-top: 0px;

    button {
      color: black;
      height: auto;
    }
  }
}

@import "../variables";

@media only screen and (max-width: $width-max-s) {
  .header-overlay {
    width: 100vw;
    height: 100vh;
    background-color: $color-blue-hyperlight;
    .header-content-wrapper {
      display: flex;
      align-items: center;
      @include setOuterPaddings(true, true);
    }
    .header-navigation {
      position: relative;
    }

    .header-navigation-list {
      padding-top: 64px;
    }

    .header-navigation-list {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin-left: 2px;
      gap: 4px;
    }
    .header-navigation-item p {
      padding: 0px;
      font-family: "Crimson Text", serif;
      font-size: 24px;
    }

    .header-navigation-item:hover {
      text-shadow: 0.4px 0px;
    }

    .header-navigation-item-active p {
      font-weight: 100;
      text-shadow: 0.4px 0px;
    }
  }
}

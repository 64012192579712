@import "../variables";

.footer {
  background-color: $color-blue-light;
}

.footer-content-wrapper {
  display: flex;
  flex-direction: column;
  color: $color-darkblue;
  @include setOuterPaddings(false, true);
  @include setGap("large");
  @include setContentMaxWidth();
}

.footer-image {
  height: 28px;
  object-fit: contain;
  align-self: start;
}

.footer-slogan {
  display: flex;
  flex-direction: column;
  @include setGap("large");
}
.footer-slogan-text {
  width: 264px;
}

.footer-slogan-button {
  width: 264px;
}

.footer-slogan-content {
  display: flex;
  flex-direction: column;
  gap: $x1;
}

.footer-information {
  display: flex;
  flex-direction: column;
  @include setGap("medium");
  h3 {
    margin-bottom: 10px;
    line-height: 1em;
  }
}

.footer-information-content-items {
  margin-left: 2px;
  p {
    line-height: 18px;
  }
  p:not(:first-of-type) {
    padding-top: 6px;
  }
}

.footer-information-contact {
  .footer-information-content-items > p {
    display: flex;
    align-items: center;

    &:first-of-type {
      padding-bottom: 2px;
    }
  }
}

.footer-information-contact-icon {
  font-size: 1.25em;
  padding-left: 2px;
  padding-right: 6px;
}

@media only screen and (min-width: $width-xs) {
  .footer-slogan {
    flex-direction: row;
  }
  .footer-slogan-content {
    width: 100%;
  }
  .footer-slogan-button {
    align-self: flex-end;
    width: 100%;
    padding: 0px 24px;
  }
  .footer-information {
    flex-direction: row;
    & > * {
      width: 50%;
    }
  }
}

@media only screen and (min-width: $width-m) {
  .footer-content-wrapper {
    flex-direction: row;
  }
  .footer-slogan {
    flex-direction: column;
    flex-grow: 1;
  }
  .footer-slogan-button {
    align-self: flex-start;
    width: 264px;
  }
  .footer-information {
    flex-grow: 1;
  }
}

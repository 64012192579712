@import "../variables";

.content-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  h3 {
    padding-bottom: $x0_5;
    @media only screen and (min-width: $width-m) {
      padding-bottom: $x1;
    }
  }
}
.content-block-main-content {
  display: flex;
  flex-direction: column;
  position: relative;
}
.content-block-additional-content {
  @include setInnerPaddings();
}

.content-block-with-image {
  box-shadow: $box-shadow-around;
  border-radius: $border-radius;
}

.content-block-without-image {
  .content-block-information {
    @include setInnerPaddings(false, false, false, "s");
  }
}

.content-block-image-wrapper {
  position: relative;
  line-height: 0;
}
.content-block-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $border-radius $border-radius 0 0;
}

.content-block-information {
  display: flex;
  flex-direction: column;
  @include setInnerPaddings();
}

.content-block-information-header {
  display: flex;
  flex-direction: column;
  @include setGap();
}

.content-block-information-content {
  display: flex;
  flex-direction: column;
  @include setGap("large");
}

.content-block-text-container {
  display: flex;
  flex-direction: column;
  gap: $inner-gap-small-s;
}

.content-block-expander-button-open {
  position: relative;
  border: none;
  width: 100%;
  padding-left: 40px;
  border-radius: 0px;
  box-shadow: none;
  .expander-button-open-extra-icon {
    position: absolute;
    font-size: 24px;
    top: 11px;
    left: -28px;
  }
}

@media only screen and (max-width: $width-max-s) {
  .content-block-without-image {
    .content-block-information {
      padding-bottom: $x5;
    }
    &:last-child {
      .content-block-information {
        padding-bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: $width-m) {
  .content-block-main-content {
    flex-direction: row;
  }

  .content-block-image {
    position: absolute;
    border-radius: $border-radius 0 0 $border-radius;
  }

  .content-block-direction-reverse {
    .content-block-main-content {
      flex-direction: row-reverse;
    }
    .content-block-image {
      border-radius: 0 $border-radius $border-radius 0;
    }
  }

  .content-block-with-image .content-block-main-content > * {
    width: 50%;
  }

  .content-block-without-image {
    box-shadow: $box-shadow-around;
    border-radius: $border-radius;
  }
  .content-block-text-container {
    gap: $inner-gap-small-m;
  }
}

@media only screen and (min-width: $width-l) {
  .content-block-expander-button-open {
    .expander-button-open-extra-icon {
      left: -33px;
    }
  }
  .content-block-text-container {
    gap: $inner-gap-small-l;
  }
}

.services {
  .content-block {
    box-shadow: none;

    @media only screen and (max-width: $width-max-s) {
      .content-block-information {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: -12px;
      }
    }

    @media only screen and (min-width: $width-m) {
      margin-top: -32px;
    }
  }
}

//About page story card
.about-story-content-block {
  .content-block-image {
    filter: opacity(0.9);
  }
}

@import "../variables";

@include alignServiceHeaderWithCotentBlock();

.content-block-container-content-wrapper {
  display: flex;
  flex-direction: column;
  @include setOuterPaddings();
  @include setContentMaxWidth();
}

.services-content-blocks-container {
  .content-block-container-content-wrapper {
    gap: $x6;
  }
  @media only screen and (min-width: $width-s) {
    .content-block-container-content-wrapper {
      gap: $x6;
    }
  }
  @media only screen and (min-width: $width-m) {
    .content-block-container-content-wrapper {
      gap: $x7;
    }
  }
  @media only screen and (min-width: $width-l) {
    .content-block-container-content-wrapper {
      gap: $x11;
      padding-top: $x11;
      padding-bottom: $x11;
    }
  }
}

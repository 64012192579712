@import "../variables";

@mixin setInfoCardValueWidth() {
  min-width: calc(
    (100vw - 84px) / 3
  ); //margins: 64px, gaps: 8px, paddings: 12px

  @media only screen and (min-width: 455px) {
    min-width: calc(
      (100vw - 92px) / 4
    ); //margins: 64px, gaps: 12px, paddings: 16px
  }
  @media only screen and (min-width: 550px) {
    min-width: calc((100vw - 100px) / 5);
  } //margins: 64px, gaps: 16px, paddings: 20px
  @media only screen and (min-width: $width-m) {
    min-width: 0px;
  }
}

.info-card-value {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  height: 100%;
  flex: 1 0;
  flex-direction: column;
  align-items: center;
  @include setExpanderPaddings(true);
  @include setGap("small");
  @include setInfoCardValueWidth();

  .info-card-icon-wrapper {
    display: flex;
    height: 36px;
    width: auto;
  }
  .info-card-text p {
    font-size: $text-extrasmall-s;
  }

  @media only screen and (min-width: $width-m) {
    height: auto;
    .info-card-icon-wrapper {
      margin-top: $x0_5;
      height: 40px;
      width: auto;
    }
    .info-card-text p {
      font-size: $text-small-m;
    }
  }
  @media only screen and (min-width: $width-l) {
    .info-card-icon-wrapper {
      height: 46px;
    }
    .info-card-text p {
      font-size: $text-small-l;
    }
  }
  @media only screen and (min-width: $width-xl) {
    .info-card-icon-wrapper {
      height: 50px;
    }
  }

  &:not(.info-card-active):hover {
    @media only screen and (min-width: $width-m) {
    }
    @media only screen and (min-width: $width-l) {
    }
    @media only screen and (min-width: $width-xl) {
    }
  }

  &.info-card-active {
    border: 1px solid $color-darkblue;
    .info-card-text p {
      font-weight: 600;
    }
    @media only screen and (min-width: $width-m) {
    }
    @media only screen and (min-width: $width-l) {
    }
    @media only screen and (min-width: $width-xl) {
    }
  }

  &.info-card-disactive {
    background-color: $color-lightgray;
    > * {
      opacity: 0.5;
    }
    &:hover {
      background-color: $color-lightgray-hover;
      > * {
        opacity: 1;
      }
    }
  }
}

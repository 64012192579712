@import "../variables";

.contact-card-item {
  display: flex;
  flex-direction: column;
}
.contact-card-item-information {
  display: flex;
  flex-direction: column;
  gap: 2px;
  h4 {
    white-space: nowrap;
  }
}

.contact-card-persons {
  .contact-card-content-wrapper {
    gap: $x3;
  }
}

.contact-card-item-person {
  @include setGap("medium");
  margin-top: 14px;

  .contact-card-item-image-wrapper {
    width: 140px;
    height: 140px;
    margin-top: -14px;
  }

  .contact-card-item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $border-radius;
  }
  .contact-card-item-information {
    margin-top: -4px;
    gap: $x1;
  }

  .contact-card-item-information-items {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (min-width: $width-s) {
  .contact-card-item {
    flex-direction: row;
  }
}

@media only screen and (min-width: $width-m) {
  .contact-card-persons {
    .contact-card-content-wrapper {
      @include setGap("medium");
    }
  }

  .contact-card-item-person {
    .contact-card-item-image-wrapper {
      width: 160px;
      height: 160px;
    }
  }
}

@media only screen and (min-width: $width-l-plus) {
  .contact-card-item-person {
    margin-top: 8px;

    .contact-card-item-image-wrapper {
      margin-top: -8px;
      width: 160px;
      min-width: 160px;
      height: 160px;
    }
  }
}

@import "../variables";

.action-button {
  height: 50px;
  padding: 0px 24px;
  margin-right: auto;
  border: 1px solid $color-darkblue;
  border-radius: $border-radius;
  white-space: nowrap;
  color: $color-darkblue;
  background-color: $color-white;
  box-shadow: $box-shadow-below;

  p {
    font-size: inherit;
    font-weight: inherit;
  }
}

.action-button-with-hover-effect:hover {
  text-shadow: 0.3px 0px;
  box-shadow: $box-shadow-around-inset;
  background-color: $color-button-hover;
}

.action-button-with-image {
  align-self: flex-start;
  border: none;
  padding: 0px;

  img {
    height: 100%;
    object-fit: contain;
  }
}

.action-button-with-icon {
  display: flex;
  align-items: center;
  span {
    margin-left: 28px;
    font-size: 28px;
  }
}

.action-button-with-content {
  border: none;
}

.action-button-wide {
  width: 100%;
  margin: auto;
  max-width: 400px;
}

@media only screen and (min-width: $width-xs) {
  .action-button {
    padding: 0px 32px;
  }
  .action-button-with-image {
    padding: 0px;
  }
  .action-button-with-icon {
    span {
      margin-left: 32px;
    }
  }
}

@import "../variables";

.info-card-ref {
  width: 100%;
}
.info-card {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 84px;
  background-color: $color-white;
  box-shadow: $box-shadow-around;
  border-radius: $border-radius;
  @include setInnerPaddings(false, false, false, "m", false, true);
  @include setGap("medium");
}

.info-card-icon-wrapper {
  display: none;
  align-items: flex-end;
  justify-content: center;
  width: 40px;
  height: 32px;
}

.info-card-icon {
  max-width: 100%;
  max-height: 100%;
}

.info-card-logo {
  height: 20px;
}

@media only screen and (min-width: $width-xs) {
  .info-card {
    height: 96px;
  }
  .info-card-logo {
    height: 22px;
  }
  .info-card-icon-wrapper {
    display: flex;
  }
}

@media only screen and (min-width: $width-m) {
  .info-card {
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .info-card-logo {
    height: 15.5px;
  }

  .info-card-icon-wrapper {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (min-width: $width-l) {
  .info-card-logo {
    height: 20px;
  }
  .info-card-icon-wrapper {
    width: 55px;
    height: 55px;
  }
}

@media only screen and (min-width: $width-xl) {
  .info-card-logo {
    height: 22px;
  }
  .info-card-icon-wrapper {
    width: 60px;
    height: 60px;
  }
}

@import "../variables";

.pricing-table-unit {
  tr td:first-of-type {
    p {
      font-weight: 400;
    }
  }
  tr td:last-of-type {
    p {
      padding-left: 4px;
    }
  }
}

.pricing-table-unit:not(:last-of-type)::after {
  content: "";
  height: 16px;
  display: table-row;
}

@import "../variables";

.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: hidden;
  height: $header-height;
  z-index: 2;
  background-color: $color-white;
  color: $color-darkblue;
  box-shadow: $box-shadow-below;
}

.header-content-wrapper {
  display: flex;
  align-items: center;
  @include setOuterPaddings(true, true);
  @include setContentMaxWidth();
}

.header-navigation {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}

.header-navigation-list {
  display: flex;
  align-items: center;
  @include setNavigationGap();
}

.header-navigation-item p {
  padding-left: 2px;
  padding-right: 2px;
}
.header-navigation-item:not(.header-navigation-item-active):hover {
  text-shadow: 0.3px 0px;
}
.header-navigation-item-logo,
.header-navigation-button {
  z-index: 4;
  position: absolute;
}
.header-navigation-item-logo {
  top: 20px;
  left: 0px;
}
button.header-navigation-button {
  display: none;
  top: 17px;
  right: 0px;
  height: auto;
  span {
    font-size: 24px;
  }
}

.header-navigation-item-active {
  p {
    font-weight: 600;
    padding-top: 4px;
    padding-bottom: 2px;
    border-bottom: 2px solid;
    position: relative;
  }
}

.header-image {
  height: 24px;
}

@media only screen and (max-width: $width-max-s) {
  .header-navigation-list {
    display: none;
  }
  button.header-navigation-button {
    display: block;
  }
}
